// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReplayRun-module__button__j1CrT {
  width: 30px;
  padding: 0 !important;
}
.ReplayRun-module__replayRunIcon__bW_4J {
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ReplayRun/ReplayRun.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,qBAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".button {\n  width: 30px;\n  padding: 0 !important;\n}\n.replayRunIcon {\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ReplayRun-module__button__j1CrT`,
	"replayRunIcon": `ReplayRun-module__replayRunIcon__bW_4J`
};
export default ___CSS_LOADER_EXPORT___;
