// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-table {
  position: relative;
}
.app-table table {
  background-color: white;
}
.app-table .table-row-highlighted td {
  background-color: #f4ebff !important;
}
.app-table .ant-pagination-disabled:has(> .anticon) {
  cursor: pointer !important;
}
.app-table.with-export .ant-table-pagination.ant-pagination {
  right: 50px;
}
.app-table.with-export .export-button {
  position: absolute;
  right: 0;
  top: 0;
}
.app-table .actions-cover {
  min-height: 32px;
  padding-bottom: 10px;
  display: flex;
}
.app-table .actions-cover:empty {
  min-height: 0;
  margin: 0;
}
.app-table .actions-cover .table-search {
  width: 230px;
}
.app-table .actions-cover .table-date-picker {
  padding-top: 6px;
  padding-bottom: 6px;
}
.app-table .actions-cover .table-action-left,
.app-table .actions-cover .table-actions-extra {
  margin-right: 8px;
}
.app-table .edit-actions {
  max-width: 60px;
  width: 60px;
}
.app-table .edit-actions span {
  margin-right: 6px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/table/Table.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,uBAAA;AADJ;AAKI;EACE,oCAAA;AAHN;AANA;EAcI,0BAAA;AALJ;AAQE;EAEI,WAAA;AAPN;AAKE;EAMI,kBAAA;EACA,QAAA;EACA,MAAA;AARN;AAjBA;EA8BI,gBAAA;EACA,oBAAA;EACA,aAAA;AAVJ;AAYI;EACE,aAAA;EACA,SAAA;AAVN;AAaI;EACE,YAAA;AAXN;AAcI;EACE,gBAAA;EACA,mBAAA;AAZN;AAeI;;EAEE,iBAAA;AAbN;AArCA;EAuDI,eAAA;EACA,WAAA;AAfJ;AAzCA;EA2DM,iBAAA;EACA,eAAA;AAfN","sourcesContent":["@import '@css/variables.less';\n\n.app-table {\n  position: relative;\n\n  & table {\n    background-color: white;\n  }\n\n  & .table-row-highlighted {\n    & td {\n      background-color: @highlight-color !important;\n    }\n  }\n\n  .ant-pagination-disabled:has(> .anticon) {\n    cursor: pointer !important;\n  }\n\n  &.with-export {\n    .ant-table-pagination.ant-pagination {\n      right: 50px;\n    }\n\n    .export-button {\n      position: absolute;\n      right: 0;\n      top: 0;\n    }\n  }\n\n  .actions-cover {\n    min-height: 32px;\n    padding-bottom: 10px;\n    display: flex;\n\n    &:empty {\n      min-height: 0;\n      margin: 0;\n    }\n\n    & .table-search {\n      width: 230px;\n    }\n\n    & .table-date-picker {\n      padding-top: 6px;\n      padding-bottom: 6px;\n    }\n\n    & .table-action-left,\n    .table-actions-extra {\n      margin-right: 8px;\n    }\n  }\n\n  .edit-actions {\n    max-width: 60px;\n    width: 60px;\n\n    span {\n      margin-right: 6px;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
