// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.procedure-permission-error .ant-result-subtitle,
.playground-permission-error .ant-result-subtitle {
  position: relative;
  width: 500px;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/PermissionError.less"],"names":[],"mappings":"AAIE;;EACE,kBAAA;EACA,YAAA;EACA,cAAA;AAFJ","sourcesContent":["@import '@css/variables.less';\n\n.procedure-permission-error,\n.playground-permission-error {\n  & .ant-result-subtitle {\n    position: relative;\n    width: 500px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
