// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RunFactRendererV2-module__stringFactContainer__uN30S {
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  padding: 4px 8px;
  width: 100%;
  margin-bottom: 8px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  overflow: hidden;
  min-width: 0;
  max-width: 100%;
}
.RunFactRendererV2-module__workerContainer__InPup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.RunFactRendererV2-module__workerButton__Ya2bv {
  height: 32px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #f0f0f0;
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 8px;
  width: fit-content;
}
.RunFactRendererV2-module__workerButton__Ya2bv:hover {
  border: 1px solid #b37feb;
  background: #efdbff;
}
.RunFactRendererV2-module__noPadding__PeEQn {
  padding: 0 !important;
}
.RunFactRendererV2-module__lineFactContainer__vNWd2 {
  display: flex;
  flex-direction: column;
}
.RunFactRendererV2-module__debuggerContainer__XIdn8 {
  display: flex;
  flex-direction: column;
}
.RunFactRendererV2-module__blueLink__ggUBL {
  color: #597ef7;
}
`, "",{"version":3,"sources":["webpack://./src/components/playground2/RunFactRendererV2.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,2CAAA;EACA,kCAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAAF;AAGA;EACE,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;EACA,0BAAA;EACA,gBAAA;EACA,kBAAA;AADF;AAIA;EACE,yBAAA;EACA,mBAAA;AAFF;AAKA;EACE,qBAAA;AAHF;AAMA;EACE,aAAA;EACA,sBAAA;AAJF;AAOA;EACE,aAAA;EACA,sBAAA;AALF;AAQA;EACE,cAAA;AANF","sourcesContent":[".stringFactContainer {\n  display: flex;\n  align-items: center;\n  border-radius: 2px;\n  border: 1px solid var(--neutral-5, #d9d9d9);\n  background: var(--neutral-1, #fff);\n  padding: 4px 8px;\n  width: 100%;\n  margin-bottom: 8px;\n  white-space: pre-line;\n  overflow-wrap: anywhere;\n  overflow: hidden;\n  min-width: 0;\n  max-width: 100%;\n}\n\n.workerContainer {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.workerButton {\n  height: 32px;\n  border-radius: 2px;\n  border: 1px solid #d9d9d9;\n  background: #f0f0f0;\n  display: flex;\n  padding: 5px 12px;\n  align-items: center;\n  gap: 4px;\n  flex: 1 0 0;\n  color: rgba(0, 0, 0, 0.85);\n  margin-left: 8px;\n  width: fit-content;\n}\n\n.workerButton:hover {\n  border: 1px solid #b37feb;\n  background: #efdbff;\n}\n\n.noPadding {\n  padding: 0 !important;\n}\n\n.lineFactContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.debuggerContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.blueLink {\n  color: #597ef7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stringFactContainer": `RunFactRendererV2-module__stringFactContainer__uN30S`,
	"workerContainer": `RunFactRendererV2-module__workerContainer__InPup`,
	"workerButton": `RunFactRendererV2-module__workerButton__Ya2bv`,
	"noPadding": `RunFactRendererV2-module__noPadding__PeEQn`,
	"lineFactContainer": `RunFactRendererV2-module__lineFactContainer__vNWd2`,
	"debuggerContainer": `RunFactRendererV2-module__debuggerContainer__XIdn8`,
	"blueLink": `RunFactRendererV2-module__blueLink__ggUBL`
};
export default ___CSS_LOADER_EXPORT___;
