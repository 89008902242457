// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppPage-module__appPage__llzhd {
  background: #f0f2f5;
}
.AppPage-module__mainLayout__y9neO {
  height: calc(100vh - 42px);
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AppPage.module.less"],"names":[],"mappings":"AAGA;EACE,mBAAA;AAFF;AAKA;EACE,0BAAA;EACA,gBAAA;AAHF","sourcesContent":["// Application Page Styles (Applies to all pages)\n@import '@css/variables.less';\n\n.appPage {\n  background: @page-bg-color;\n}\n\n.mainLayout {\n  height: calc(100vh - 42px);\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appPage": `AppPage-module__appPage__llzhd`,
	"mainLayout": `AppPage-module__mainLayout__y9neO`
};
export default ___CSS_LOADER_EXPORT___;
