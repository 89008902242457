// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-department-form {
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  padding: 24px;
}
.create-department-form .alert {
  margin-bottom: 16px;
}
.create-department-form .heading {
  margin-bottom: 16px;
}
.create-department-form .actions {
  margin-bottom: 12px;
  margin-top: -16px;
}
.create-department-form .ant-divider-horizontal {
  margin: 16px 0;
}
.create-department-popup .create-department-form {
  padding: 0;
}
.create-department-popup .heading {
  position: relative;
  top: -6px;
}
.create-department-popup .ant-modal-body {
  padding-bottom: 1px;
}
.create-department-popup .actions .ant-space {
  display: flex;
  justify-content: end;
}
`, "",{"version":3,"sources":["webpack://./src/popups/CreateDepartmentPopup/CreateDepartmentPopup.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,uBAAA;EACA,aAAA;AACF;AALA;EAOI,mBAAA;AACJ;AARA;EAWI,mBAAA;AAAJ;AAXA;EAeI,mBAAA;EACA,iBAAA;AADJ;AAfA;EAoBI,cAAA;AAFJ;AAMA;EAEI,UAAA;AALJ;AAGA;EAMI,kBAAA;EACA,SAAA;AANJ;AADA;EAWI,mBAAA;AAPJ;AAWI;EACE,aAAA;EACA,oBAAA;AATN","sourcesContent":[".create-department-form {\n  max-width: 500px;\n  margin: 0 auto;\n  background-color: white;\n  padding: 24px;\n\n  .alert {\n    margin-bottom: 16px;\n  }\n\n  .heading {\n    margin-bottom: 16px;\n  }\n\n  .actions {\n    margin-bottom: 12px;\n    margin-top: -16px;\n  }\n\n  .ant-divider-horizontal {\n    margin: 16px 0;\n  }\n}\n\n.create-department-popup {\n  .create-department-form {\n    padding: 0;\n  }\n\n  .heading {\n    position: relative;\n    top: -6px;\n  }\n\n  .ant-modal-body {\n    padding-bottom: 1px;\n  }\n\n  .actions {\n    & .ant-space {\n      display: flex;\n      justify-content: end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
